import { mapState, mapGetters } from 'vuex';

import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnDocItem from '../../../shared/components/KnDocItem.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnImageDocItem from '../../../shared/components/KnImageDocItem.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnSubjectsAutocomplete from '../KnSubjectsAutocomplete/KnSubjectsAutocomplete.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import { getFullName, objectHasNulls } from '../../../shared/helpers/dataUtils';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import {
  fetchClassroom,
  fetchGroupSucursals,
  fetchSchoolCycles,
} from '../../../configuration/helpers/KnGroupsOptions';
import {
  fetchSchoolLevels,
  fetchSubjects,
  // fetchSyllabus,
} from '../../helpers/syllabusOptions';
import { fetchTypesEmployee } from '../../../configuration/helpers/KnEmployeeTypesOptions';
import {
  fetchEmployee,
  fetchEmployeeByName,
  // postCreateSpecialGroup,
  postCreateSpecialGroupV2,
} from '../../helpers/employeeOptions';
import { fetchStudents } from '../../helpers/reportCardOptions';
import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import { fetchStudentByName } from '../../../students/helpers/utilsStudent';
import {
  fetchSubjectTeacher,
  putGroup,
  putSubjectTeacher,
} from '../../helpers/subjectsTeacherOptions';

export default {
  name: 'KnFormSpecialGroups',
  components: {
    KnAutoComplete,
    KnBackToTopButton,
    KnCheckBox,
    KnDocItem,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormSubtitle,
    KnFormTitle,
    KnImageDocItem,
    KnLocalAlert,
    KnPagination,
    KnSubjectsAutocomplete,
    KnTabs,
    KnTextField,
  },
  mixins: [paginationMixin, validationFormMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    is_especial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [
        { name: 'Datos del grupo', value: 33 },
        { name: 'Asignación de profesores', value: 66 },
        { name: 'Asignación de alumnos', value: 100 },
      ],
      valueDeterminate: 33,
      routerName: 'Grupos Especiales',
      resource: 'grupo',
      bkEntity: null,
      bkReadonly: false,
      groupId: null,
      groupImage: null,
      specialGroupInfo: {
        nombre_grupo: '',
        id_profesor: null,
        lista_materias_empalmar: [],
        lista_detalle_alumnos: [],
        id_nivel_educativo: null,
        id_institucion_educativa: null,
        id_salon_clases: null,
        id_sucursal: null,
      },
      studentDetail: {
        id_alumno: null,
        id_nivel_educativo: null,
        id_materia: null,
      },
      availableSubjects: [],
      cycles: [],
      levels: [],
      syllabus: [],
      groups: [],
      teachers: [],
      subjects: [],
      students: [],
      branches: [],
      classrooms: [],
      selectedStudents: [],
      loadingGroups: false,
      loadingEmployees: false,
      loadingStudents: false,
      teacherType: null,
      inputSubject: null,
      inputTeacher: null,
      inputStudent: null,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      syllabusMessage: [],
      createdSubjectsTeacher: [],
      teacherPagination: {},
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    ...mapGetters(['currentSchoolYear', 'currentSchoolLevel']),
    isNewMode() {
      return this.entity === null || this.bkEntity === null;
    },
    title() {
      return this.isNewMode
        ? 'Agregar grupo especial'
        : 'Editar grupo especial';
    },
    isReadonly() {
      return this.readonly || this.bkReadonly;
    },
    successAlertText() {
      return this.isNewMode
        ? 'Grupo especial creado exitosamente!'
        : 'Grupo especial actualizado exitosamente!';
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info';
    },
    warningText() {
      return this.warnings.length ? this.warnings.join(',') : null;
    },
    selectedSchoolLevelName() {
      let schoolLevelName = null;
      if (this.specialGroupInfo.id_nivel_educativo) {
        const findedLevel = this.levels.find(
          (l) => l.id === this.specialGroupInfo.id_nivel_educativo
        );
        if (findedLevel) {
          schoolLevelName = findedLevel.nombre;
        }
      }
      return schoolLevelName;
    },
    filteredAvailableStudents() {
      return this.students.filter(
        (aStudent) =>
          !this.selectedStudents
            .map((sStudent) => sStudent.id)
            .includes(aStudent.id)
      );
      // return this.students.filter(aStudent => !this.selectedStudents.some(sStudent => sStudent.id === aStudent.id))
    },
    filteredSelectedSubjects() {
      return this.subjects.filter((subject) =>
        this.specialGroupInfo.lista_materias_empalmar.some(
          (s) => s === subject.id
        )
      );
    },
    invalidFields() {
      return (
        objectHasNulls(this.specialGroupInfo) || !this.selectedStudents.length
      );
    },
  },
  watch: {
    inputSubject: {
      async handler() {
        await this.searchSubjects();
      },
    },
    // inputTeacher: {
    //   async handler() {
    //     await this.searchEmployee();
    //   },
    // },
  },
  async created() {
    this.loading = true;
    this.specialGroupInfo.id_institucion_educativa = this.institutionId;

    this.bkEntity = this.entity;
    this.bkReadonly = this.readonly;

    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.showAlert = true;
    Object.assign(this.teacherPagination, this.pagination);
    this.setPaginationLimit(30);

    await this.fetchData();

    if (!this.isNewMode) {
      this.fillSpecialGroup();
    }

    this.showAlert = false;
    this.loading = false;
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    getFullName: getFullName,
    async fetchData() {
      const { ok: okSchoolCycles, data: dataSchoolCycles } =
        await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 1000,
        });
      const { ok: okSchoolLevels, data: dataSchoolLevels } =
        await fetchSchoolLevels({
          institutionId: this.institutionId,
          systemStatus: true,
          limit: 100,
        });
      // const { ok: okSyllabus, data: dataSyllabus } = await fetchSyllabus({
      //   institutionId: this.institutionId,
      //   systemStatus: true,
      //   limit: 2000,
      // });
      const { ok: okTypeEmployee, data: dataTypeEmployee } =
        await fetchTypesEmployee({
          dato: 'docente',
          institutionId: this.institutionId,
          systemStatus: true,
        });
      this.teacherType = okTypeEmployee
        ? dataTypeEmployee.length
          ? dataTypeEmployee[0]
          : null
        : null;
      const { ok: okEmployee, data: dataEmployee } = await fetchEmployee({
        institutionId: this.institutionId,
        typeEmployee: this.teacherType ? this.teacherType.id : null,
        systemStatus: true,
        limit: this.teacherPagination.limit,
      });
      const { ok: okSubjects, data: dataSubjects } = await fetchSubjects({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 500,
      });
      const { ok: okBranches, data: dataBranches } = await fetchGroupSucursals({
        institutionId: this.institutionId,
        systemStatus: true,
        limit: 30,
      });
      // const { ok: okStudent, data: dataStudent } = await fetchStudents({
      //   institutionId: this.institutionId,
      //   systemStatus: true,
      //   limit: 30,
      // });
      // const {ok: okClassroom, data: dataClassroom} = await fetchClassroom({
      //   institutionId: this.institutionId,
      //   systemStatus: true,
      //   limit: 30
      // })
      this.branches = okBranches ? dataBranches : [];
      this.cycles = okSchoolCycles ? dataSchoolCycles : [];
      this.levels = okSchoolLevels ? dataSchoolLevels : [];
      // this.syllabus = okSyllabus ? dataSyllabus : [];
      this.subjects = okSubjects ? dataSubjects : [];
      this.teachers = okEmployee ? dataEmployee : [];
    },
    async setClassroom() {
      this.loading = true;
      const { ok: okClassroom, data: dataClassroom } = await fetchClassroom({
        institutionId: this.institutionId,
        sucursal: this.specialGroupInfo.id_sucursal,
        systemStatus: true,
        limit: 500,
      });
      this.classrooms = okClassroom ? dataClassroom : [];
      this.loading = false;
    },
    async searchSubjects() {
      try {
        if (!this.loadingGroups) {
          this.loadingGroups = true;
          this.subjects = [];
          const { ok: okSubjects, data: dataSubjects } = await fetchSubjects({
            institutionId: this.institutionId,
            name: this.inputSubject,
            systemStatus: true,
            limit: 2000,
          });
          if (okSubjects) {
            this.subjects = dataSubjects;
          }
        }
      } catch (error) {
        console.log('Error al intentar obtener materias', error);
      } finally {
        this.loadingGroups = false;
      }
    },
    async searchEmployee() {
      try {
        if (!this.loadingEmployees) {
          this.loadingEmployees = true;
          this.teachers = [];
          if (this.inputTeacher && this.inputTeacher !== '') {
            const { ok, data } = await fetchEmployeeByName(
              this.inputTeacher,
              this.teacherPagination,
              true,
              this.institutionId,
              this.teacherType ? this.teacherType.id : null
            );
            // console.log('data fetchEmployeeByName', data.results);

            this.teachers = ok ? data.results : [];
            // console.log('this.teachers', this.teachers);
          } else {
            const { ok: okEmployee, data: dataEmployee } = await fetchEmployee({
              institutionId: this.institutionId,
              typeEmployee: this.teacherType ? this.teacherType.id : null,
              systemStatus: true,
              limit: this.teacherPagination.limit,
            });
            this.teachers = okEmployee ? dataEmployee : [];
          }
        }
      } catch (error) {
        console.log('Error al intentar obtener empleados', error);
      } finally {
        this.loadingEmployees = false;
      }
    },
    async setStudents() {
      this.students = [];
      this.selectedStudents = [];
      if (this.specialGroupInfo.id_nivel_educativo) {
        this.searchStudent(1);
      }
    },
    async searchStudent(page = 1) {
      try {
        // console.log('searchStudent page', page);

        if (!this.loadingStudents) {
          this.loadingStudents = true;
          this.students = [];
          this.setPaginationPage(page);
          if (this.inputStudent && this.inputStudent !== '') {
            const { ok, data } = await fetchStudentByName(
              this.inputStudent,
              this.pagination,
              true,
              this.institutionId,
              this.selectedSchoolLevelName
            );
            if (ok) {
              this.students = data.results;
              this.setPaginationCount(data.results.length);
            } else {
              this.setPaginationCount(0);
            }
          } else {
            const {
              ok: okStudent,
              data: dataStudent,
              count: countStudent,
            } = await fetchStudents({
              institutionId: this.institutionId,
              systemStatus: true,
              schoolLevelId: this.specialGroupInfo.id_nivel_educativo,
              limit: this.pagination.limit,
              offset: this.pagination.offset,
            });
            this.students = okStudent ? dataStudent : [];
            this.setPaginationCount(countStudent);
          }
        }
      } catch (error) {
        console.log('Error al intentar obtener alumnos', error);
      } finally {
        this.loadingStudents = false;
      }
    },
    addStudent(item) {
      const detailItem = { ...item, ...this.studentDetail };
      this.selectedStudents.push(detailItem);
    },
    removeStudent(item) {
      this.selectedStudents = this.selectedStudents.filter(
        (sStudent) => sStudent.id !== item.id
      );
    },
    async createSpecialGroup() {
      try {
        this.alertText = 'Creando grupo especial';
        // this.specialGroupInfo.ids_alumnos = this.selectedStudents.map(
        //   (sStudent) => sStudent.id
        // );
        this.specialGroupInfo.lista_detalle_alumnos =
          this.buildStudentsDetail();
        // console.log('Datos a grupo a mandar', this.specialGroupInfo);

        const { ok, message } = await postCreateSpecialGroupV2(
          this.specialGroupInfo
        );

        if (!ok) {
          this.errors.push(message);
        } else {
          // console.log('data', data);
        }
      } catch (error) {
        this.errors.push(error);
      }
    },
    fillSpecialGroup() {
      try {
        this.specialGroupInfo = {
          id: this.entity.id,
          nombre_grupo: this.entity.nombre_grupo,
          id_sucursal: this.entity.sucursal ? this.entity.sucursal.id : null,
          id_salon_clases: this.entity.salon_clases
            ? this.entity.salon_clases.id
            : null,
          id_ciclo_escolar: this.entity.ciclo_escolar
            ? this.entity.ciclo_escolar.id
            : null,
          id_nivel_educativo: this.entity.nivel_educativo
            ? this.entity.nivel_educativo.id
            : null,
          id_profesor: this.entity.profesores.length
            ? this.entity.profesores.map((p) => p.id)[0]
            : null,
          id_materia: this.entity.materia_profesor.length
            ? this.entity.materia_profesor.map((mp) => mp.id)[0]
            : null,
          is_especial: true,
          estatus_sistema: true,
        };
        this.selectedStudents = [...this.entity.alumnos];
      } catch (error) {
        this.errors.push('No se pudo llenar la información del grupo especial');
      }
    },
    /**
     * Ejecuta el proceso para actualizar el grupo
     * especial.
     * 1. Actualizar información del grupo
     * 2. Validar cambios sobre materias y profesores,
     * 3. Validar cambios sobre alumnos
     */
    async updateSpecialGroup() {},
    /**
     * Actualiza información del grupo. Valores
     * como nombre, nivel educativo, ciclo
     * escolar, etc.
     */
    async updateGroupInfo() {
      try {
        const group = {
          id: this.specialGroupInfo.id,
          nombre_grupo: this.specialGroupInfo.nombre_grupo,
          salon_clases: this.specialGroupInfo.id_salon_clases,
          profesores: [this.specialGroupInfo.id_profesor],
          materia_profesor: [...this.createdSubjectsTeacher],
          alumnos: [...this.selectedStudents.map((ss) => ss.id)],
          ciclo_escolar: this.specialGroupInfo.id_ciclo_escolar,
          nivel_educativo: this.specialGroupInfo.id_nivel_educativo,
          institucion_educativa: this.institutionId,
          is_especial: true,
          estatus_sistema: true,
        };
        const { ok, data, message } = await putGroup(group);
        console.log('updategroupInfo', data);

        if (!ok) {
          this.errors.push(
            'Error en actualizar información del grupo. ' + message
          );
        } else {
          this.alertText = 'Información de grupo actualizado exitósamente';
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar actualizar datos del grupo. ' + error
        );
      }
    },
    /**
     * actualiza la asignación de profesor
     * y materia. Debe revisar si hay cambio
     * de profesor se debe desasignar la materia
     * profesor del grupo, crear la nueva
     * materiaProfesor y asignarla
     */
    async updateTeacherAndSubjectAssignenment() {
      try {
        const bkTeacher = this.entity.profesores.length
          ? this.entity.profesores[0]
          : null;
        const bkSubjectTeacher = this.entity.materia_profesor.length
          ? this.entity.materia_profesor[0]
          : null;
        const bkTeacherId = bkTeacher ? bkTeacher.id : null;
        const bkSubjectTeacherId = bkSubjectTeacher
          ? bkSubjectTeacher.id
          : null;
        const bkSubjectId = bkSubjectTeacher
          ? bkSubjectTeacher.materia.id
          : null;
        if (
          bkTeacherId !== this.specialGroupInfo.id_profesor ||
          bkSubjectId !== this.specialGroupInfo.id_materia
        ) {
          let criteria = [];
          const { ok: okGetST, data: dataGetST /*, message: messageGetST*/ } =
            await fetchSubjectTeacher(this.specialGroupInfo.id_materia);
          if (okGetST) {
            criteria = dataGetST.criterios_evaluacion.map((c) => c.id);
          }
          const {
            ok: okPutST,
            data: dataPutST,
            message: messagePutST,
          } = await putSubjectTeacher({
            id: bkSubjectTeacherId,
            fecha_asignacion: new Date().toISOString().substring(0, 10),
            profesor: this.specialGroupInfo.id_profesor,
            materia: this.specialGroupInfo.id_materia,
            criterios_evaluacion: criteria,
            institucion_educativa: this.institutionId,
            estatus_sistema: true,
          });
          console.log(
            'updateTeacherAndSubjectAssignenment dataPutST',
            dataPutST
          );

          if (!okPutST) {
            this.errors.push(
              'No se pudo actualizar la materia profesor. ' + messagePutST
            );
          } else {
            this.alertText = 'Materia profesor actualizada correctamente';
          }
        }
      } catch (error) {
        this.errors.push(
          'Error al intentar actualizar materiaProfesor. ' + error
        );
      }
    },
    /**
     * Actualiza los alumnos asignados al grupo.
     * Debe checar si hay nuevos alumnos o
     * si se remueven algunos.
     */
    async updateStudentAssignement() {
      try {
        const bkStudents = this.entity.alumnos.map((s) => s.id);
        const selectedStudents = this.selectedStudents.map((ss) => ss.id);
        const studentsToAssign = this.getDifference(
          selectedStudents,
          bkStudents
        );
        const studentsToUnassign = this.getDifference(
          bkStudents,
          selectedStudents
        );
        if (studentsToAssign.length) {
          /**
           * Proceso de asignación para cada alumno
           * 1. asignar alumno a grupo
           * 2. crear cambio grupo
           * 3. crear materiaAlumno usando materiaProfesor actual
           * 4. asignar materiaAlumno
           */
        }
        if (studentsToUnassign.length) {
          /**
           * Proceso de desasignación para cada alumno
           * 1. desasignar alumno del grupo
           * 2. crear cambio grupo
           * 3. desactivar materiaAlumno de los alumnos
           */
        }
      } catch (error) {
        this.errors.push(
          'Error al actualizar alumnos del grupo especial. ' + error
        );
      }
    },
    getDifference(array1 = [], array2 = []) {
      const set1 = new Set(array1);
      const set2 = new Set(array2);

      const difference = [...set1].filter((item) => !set2.has(item));
      return difference;
    },
    async save() {
      // A guardar
      this.loading = true;
      this.alertColor = 'info';
      this.alertText = 'Cargando...';
      this.showAlert = true;
      if (this.isNewMode) {
        await this.createSpecialGroup();
      } else {
        // TODO: Como actualizar?
      }
      this.completeAlert();
    },
    buildStudentsDetail() {
      const dataToSend = this.selectedStudents.map((ss) => {
        const item = {
          id_alumno: ss.id,
          id_materia: ss.id_materia,
          id_nivel_educativo: this.specialGroupInfo.id_nivel_educativo,
        };
        return item;
      });
      return dataToSend;
    },
    getGroupNames(groups = []) {
      return groups.map((g) => g.nombre_grupo).join(', ');
    },
    saveImage(fileObj) {
      this.groupImage = fileObj;
    },
    clearImage() {
      this.groupImage = null;
    },
    completeAlert() {
      this.loading = false;

      if (this.errors.length) {
        this.alertType = 'error';
        this.alertColor = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = this.successAlertType;
        this.alertColor = 'success';
        this.alertText = this.successAlertText;
      }
    },
    // insufficientPermissionAlert() {
    //   this.alertType = 'info';
    //   this.alertText = insufficientPermissionsMessage();
    //   this.alertColor = 'warning';
    //   this.loading = false;
    //   this.showAlert = true;
    // },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.clean();
      this.closeAlert();
      this.setTabValue(this.tabs[0].value);
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    clean() {
      this.specialGroupInfo = {
        nombre_grupo: '',
        id_profesor: null,
        id_materia: null,
        ids_alumnos: [],
        id_nivel_educativo: null,
        id_institucion_educativa: this.institutionId,
        id_salon_clases: null,
        id_sucursal: null,
      };
      this.selectedStudents = [];
    },
  },
};
